






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_SCOPES_OF_FUNCTIONS } from '../store';
import { ChangeScopeOfFunctions, ScopeOfFunctions } from '../types';
import { ApiResponse } from '@/components/types';
import ScopesOfFunctionsCheckboxGroup from '../../scopeOfFunctions/components/ScopesOfFunctionsCheckboxGroup.vue';

const Conservatorship = namespace('conservatorshipToScopeOfFunctions');

@Component({
  components: {
    ScopesOfFunctionsCheckboxGroup
  }
})
export default class ScopeOfFunctionsChangeDialog extends Vue {
  @Prop({ type: Array, required: true }) scopesOfFunctions!: ScopeOfFunctions[];
  @Prop({ type: String, required: true }) conservatorship!: string;
  @Conservatorship.Action(CHANGE_SCOPES_OF_FUNCTIONS) updateScopesOFunctions!: (params: ChangeScopeOfFunctions) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;
  disabled: boolean = false;

  value: ChangeScopeOfFunctions = {
    conservatorship_id: this.conservatorship,
    scopes_of_functions: this.scopesOfFunctions.map((entry) => entry.id)
  };

  @Watch('scopesOfFunctions')
  watchScopesOfFunctions() {
    this.value = {
      conservatorship_id: this.conservatorship,
      scopes_of_functions: this.scopesOfFunctions.map((entry) => entry.id)
    };
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatorship_id: this.conservatorship,
        scopes_of_functions: this.scopesOfFunctions.map((entry) => entry.id)
      };

      return;
    }

    setTimeout(() => {
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.updateScopesOFunctions(this.value);
  }
}
